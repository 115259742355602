import React from 'react'
import './styles.module.scss'

export default class AppFooter extends React.PureComponent {

	render () {
		const year = (new Date()).getFullYear()
		return (
			<div className="container-fluid">
				<div className="footer">
					<div className="row center-xs">
						<div className="col-xs">
							<img className="bear" src={require('./bear.svg')} alt="Cool Bear Icon" />
						</div>
					</div>
					<div className="row">
						<div className="col-xs">
							<p className="dark-small">&copy; Julian Caustrita</p>
						</div>
						<div className="col-xs center-xs">
							<p className="dark-small">{year} Portfolio</p>
						</div>
						<div className="col-xs end-xs">
							<p className="dark-small">Site by Julian</p>
						</div>
					</div>
				</div>
			</div>
		)
	}

}
