import React from 'react'
import { globalHistory } from '@reach/router'
import Velocity from 'velocity-animate'

class ScrollToTop extends React.PureComponent {

	componentDidMount() {
		this.historyUnsubscribe = globalHistory.listen(() => {
			Velocity(document.body, 'scroll', {
				duration: 300,
				easing: 'easeInOutExpo'
			})
		})
	}

	componentWillUnmount() {
		this.historyUnsubscribe()
	}

	render () {
		return null
	}

}

export default ScrollToTop
