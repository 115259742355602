import React from 'react'
import { Helmet } from 'react-helmet'

class OGTags extends React.PureComponent {

	render () {
		return (
			<Helmet>
				{Object.entries(this.props).map(([property, content]) => {
					if(property === 'image') {
						content = window.location.origin + content
					}
					return (
						<meta property={`og:${property}`} content={content} key={property} />
					)
				})}
			</Helmet>
		)
	}

}

export default OGTags
