import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class HighRoadEdibles extends React.PureComponent {

	render () {
		return (
			<Portfolio title="High Road Edibles" shareImage={require('./images/og-high-road-edibles.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/highroadedibles.jpg'),
						'2x': require('./images/highroadedibles@2x.jpg'),
						'mobile': require('./images/highroadedibles-mobile.jpg')
					}}
					alt="High Road Edibles"
				/>

				<Portfolio.Blurb headline="High Road Edibles" role="Art Director / Design">
					<p>This is craft edibles. High Road Edibles is riding the craft wave in Montana and standing out from other cannabis/hemp-infused companies due to their high-quality consistently dosed products with bursts of flavor. Julian worked closely with the Partners to design the branding and product packaging for both their gummies and mints.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/packaging.gif'),
							'2x': require('./images/packaging@2x.gif')
						}}
						alt="Packaging Design"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Trekking All Possible Roads">
					<p>
						The branding needed to reflect the co-founders’ sprit of the outdoors and elevated experiences with Montana pride. A ‘High Road’ is an ode to the elevated experience one has while dosing, which ultimately became the mark. While exploring various ideas like Montana’s old American heritage, culture, and natural beauty – the final branding embraced the utter simplicity of an airy mountainscape using geometric trails forming the peaks. Julian’s branding process was to include the logos in packaging contexts to get a better vision of how each could unfold with different voices.
					</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="875"
						src={{
							'1x': require('./images/logos.jpg'),
							'2x': require('./images/logos@2x.jpg')
						}}
						alt="Logo Studies"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FullWidthImage vr={true}
					src={{
						'1x': require('./images/fullwidth-story.jpg'),
						'2x': require('./images/fullwidth-story@2x.jpg'),
						'mobile': require('./images/fullwidth-story-mobile.jpg')
					}}
					alt="Cool Ass Image of High Road Edibles"
				/>

				<Portfolio.Blurb headline="Packaging Quality and Flavor">
					<p>
						The packaging format influenced the final logo which led to various iterations of package design studies to capture the level of quality, hierarchy, and flavor the Brand needed. The other x-factor was creating a design that stood out from the growing competition within a modest budget. Explorations dug into line-work, texture, color, and typography drawing inspiration from the elevated effects the product brings and from Montana’s Native history. Julian’s design epxloration shows his creative range and ability to hone in on constructive feedback. Ultimately, the packaging was simplified to showcase patterns matching their effects loaded with color pops and refreshing contrast.
					</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages vr={true}>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/packaging.jpg'),
							'2x': require('./images/packaging@2x.jpg')
						}}
						alt="Packaging Studies"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/lifestyle1.jpg'),
							'2x': require('./images/lifestyle1@2x.jpg')
						}}
						alt="Lifestyle"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/lifestyle2.jpg'),
							'2x': require('./images/lifestyle2@2x.jpg')
						}}
						alt="Lifestyle"
					/>
				</Portfolio.FlushImages>

				<Portfolio.UpNext to="/work/tribe" title="Tribe" />

			</Portfolio>
		)
	}

}
