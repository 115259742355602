import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class ComfortZone extends React.PureComponent {

	render () {
		return (
			<Portfolio title="Comfort Zone" shareImage={require('./images/og-comfortzone.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/comfortzone.jpg'),
						'2x': require('./images/comfortzone@2x.jpg'),
						'mobile': require('./images/comfortzone-mobile.jpg')
					}}
					alt="Comfort Zone"
				/>

				<Portfolio.Blurb headline="Comfort Zone" role="UX / Art Direction / Design">
					<p>A new and unique pet product line that mimics dog and cat pheromones and reduces unwanted (bad) behaviors. The brand needed a new voice that set them apart from typical pet brands, yet one that remained relatable. Julian played a leading strategic role in the project and led the design for their responsive website.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/homepage.jpg'),
							'2x': require('./images/homepage@2x.jpg')
						}}
						alt="Comfort Zone's Homepage"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/intro-stress.jpg'),
							'2x': require('./images/intro-stress@2x.jpg')
						}}
						alt="Comfort Zone's Stress"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/intro-products.jpg'),
							'2x': require('./images/intro-products@2x.jpg')
						}}
						alt="Comfort Zone's Products"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Inspiring a Creative Strategy">
					<p>Helping consumers understand and prevent their pet’s problem behaviors from happening is challenging for a new pet brand - especially with an already frustrated audience. Julian introduced humor to the brand to cut through the frustration. ‘Pet shaming’ became the design strategy that drove product interest, brand trust and site engagement.</p>
				</Portfolio.Blurb>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/creative-strategy.jpg'),
						'2x': require('./images/creative-strategy@2x.jpg'),
						'mobile': require('./images/creative-strategy-mobile@2x.jpg')
					}}
					alt="Cool Ass Image of Comfort Zone Stuff"
				/>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/creative-strategy-laptop.jpg'),
							'2x': require('./images/creative-strategy-laptop@2x.jpg')
						}}
						alt="Comfort Zone's Strategy"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Shaping Content Purposefully">
					<p>Comfort Zone had a growing list of stress resources, dog tips, cat tips and product information to manage. Julian created story hierarchies and navigation patterns that both cat and dog sections used. Julian split these two audiences up to eliminate page density and to isolate discovery.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/ux-overview.jpg'),
							'2x': require('./images/ux-overview@2x.jpg')
						}}
						alt="Comfort Zone's Mobile Wireframes"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/ux-how-it-works.jpg'),
							'2x': require('./images/ux-how-it-works@2x.jpg')
						}}
						alt="Comfort Zone's Mobile Wireframes"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/ux-mobile-cat.jpg'),
							'2x': require('./images/ux-mobile-cat@2x.jpg')
						}}
						alt="Comfort Zone Dog and Cat"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/ux-mobile-dog.jpg'),
							'2x': require('./images/ux-mobile-dog@2x.jpg')
						}}
						alt="Comfort Zone Dog and Cat"
					/>
				</Portfolio.FlushImages>

				<Portfolio.Blurb headline="Educating Pet Owners via Collage">
					<p>Custom photo shoots capturing bad pet behaviors was a budget luxury Comfort Zone didn’t have. Throughout the experience, Julian created complex visual stories using only stock photography to support the content. Julian felt strongly that cheesy pet images needed to be avoided in order to build compelling scenes with maximum visual impact.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/digital-img-monitor.jpg'),
							'2x': require('./images/digital-img-monitor@2x.jpg')
						}}
						alt="Comfort Zone's Good/Bad Scene"
					/>
					<Portfolio.Image
						size="875"
						src={{
							'1x': require('./images/digital-img-mobile.jpg'),
							'2x': require('./images/digital-img-mobile@2x.jpg')
						}}
						alt="Comfort Zone's Collage"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/digital-img-laptop.jpg'),
							'2x': require('./images/digital-img-laptop@2x.jpg')
						}}
						alt="Comfort Zone's Drop the Mic Pic"
					/>
				</Portfolio.StackedImages>

				<Portfolio.UpNext to="/work/beercraft" title="BeerCraft" />

			</Portfolio>
		)
	}

}
