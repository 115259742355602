import React from 'react'
import { Router } from '@reach/router'

import Lindsay from './Lindsay'
import ComfortZone from './ComfortZone'
import BeerCraft from './BeerCraft'
import SSI from './SSI'
import Cline from './Cline'
import Tribe from './Tribe'
import Detente from './Detente'
import LightwellSurvey from './LightwellSurvey'
import HighRoadEdibles from './HighRoadEdibles'

const NAV = [
	{
		title: 'Détente Wines',
		slug: 'detente',
		component: Detente
	},
	{
		title: 'High Road Edibles',
		slug: 'high-road-edibles',
		component: HighRoadEdibles
	},
	{
		title: 'Lindsay',
		slug: 'lindsay',
		component: Lindsay
	},
	{
		title: 'Comfort Zone',
		slug: 'comfort-zone',
		component: ComfortZone
	},
	{
		title: 'Lightwell Survey',
		slug: 'lightwell-survey',
		component: LightwellSurvey
	},
	{
		title: 'Cline Cellars',
		slug: 'cline',
		component: Cline
	},
	{
		title: 'Tribe',
		slug: 'tribe',
		component: Tribe
	},
	{
		title: 'BeerCraft',
		slug: 'beercraft',
		component: BeerCraft
	},
	{
		title: 'SSI',
		slug: 'ssi',
		component: SSI
	}
]

class WorkRoute extends React.PureComponent {

	static nav = NAV

	render () {
		return (
			<Router>
				{NAV.map(({ slug, component: Component }) => (
					<Component path={slug} key={slug} />
				))}
			</Router>
		)
	}

}

export default WorkRoute
