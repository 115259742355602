import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class LightwellSurvey extends React.PureComponent {

	render () {
		return (
			<Portfolio title="Lightwell Survey" shareImage={require('./images/og-lightwellsurvey.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/lightwellsurvey.jpg'),
						'2x': require('./images/lightwellsurvey@2x.jpg'),
						'mobile': require('./images/lightwellsurvey-mobile.jpg')
					}}
					alt="Lightwell Survey"
				/>

				<Portfolio.Blurb headline="Lightwell Survey" role="Partner / Branding / UX / Design">
					<p>A new era of Virginia wine that reflects the more forward-thinking producers in the world. With the unique terroir and relatively high altitudes, the winery is able to yield subtle, fine tannin reds, and mineral-aromatic whites. Julian was responsible for branding, creative and digital strategy.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/bottles.jpg'),
							'2x': require('./images/bottles@2x.jpg')
						}}
						alt="3 wine bottles"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Shining New Light on VA Wine">
					<p>
						A Lightwell is designed to allow light to illuminate into a dark place. That’s what Lightwell Survey is doing in a sense – Shining a modern avant-garde style light to a traditional wine-scape. The word ‘Survey’ is about finding the best grapes in lesser known areas. Julian created a logo that captures the neo-punk/horror persona of the brand with a focus on Virginia pride shown in the broken “W”, a symbol emphasizing <strong>V</strong>irginia <strong>W</strong>ine.
					</p>
				</Portfolio.Blurb>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/branding1.jpg'),
							'2x': require('./images/branding1@2x.jpg')
						}}
						alt="Logo inspiration"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/branding2.jpg'),
							'2x': require('./images/branding2@2x.jpg')
						}}
						alt="Logo designs"
					/>
				</Portfolio.FlushImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/businesscard.jpg'),
							'2x': require('./images/businesscard@2x.jpg')
						}}
						alt="Business card design"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Sipping Art. Appreciating Art.">
					<p>
						Lightwell Survey's wine labels are beautifully illustrated, intriguing, and moody. The creative wine names and illustrative work pushes the envelope of traditional wine label design. Winemaking is art. Julian’s full-wrap label layout focuses on the label artwork as the brand’s point of recognition. Lightwell Survey fans get to see their favorite bottle’s storyline continue each year in a different illustrative way. Label illustrations are masterfully done by Lightwell Survey’s talented partner, John DeNapoli.
					</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/los-idiots.gif'),
							'2x': require('./images/los-idiots@2x.gif')
						}}
						alt="Los Idiots wine label: Clowns making wine"
					/>
				</Portfolio.StackedImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/hintermen.gif'),
							'2x': require('./images/hintermen@2x.gif')
						}}
						alt="Hintermen wine label: Men hunting a buffalo"
					/>
				</Portfolio.StackedImages>

				<Portfolio.StackedImages vr={true}>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/the-weird-ones-are-wolves.gif'),
							'2x': require('./images/the-weird-ones-are-wolves@2x.gif')
						}}
						alt="The Weird Ones Are Wolves wine label: Two wolves fighting"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/lifestyle.jpg'),
							'2x': require('./images/lifestyle@2x.jpg')
						}}
						alt="Tasting room"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/closeup.jpg'),
							'2x': require('./images/closeup@2x.jpg')
						}}
						alt="Close up of Los Idiots"
					/>
				</Portfolio.FlushImages>

				<Portfolio.Blurb headline="Breaking Tradition on Instagram">
					<p>
						Winery social media content tend to be aspirational clichés. This winery doesn’t have the budget or desire for that same script. For the first 2 years of Lightwell Survey’s Instagram life, Julian developed original content that poked fun at the cliché by adding humor, memes, and blatantly photoshopped imagery of Lightwell Survey wine bottles in others' images. The intitial fan base really gravitated toward the lighthearted tone that the brand teased, gaining an average ~10% engagement rate.
					</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/insta.jpg'),
							'2x': require('./images/insta@2x.jpg')
						}}
						alt="Instagram grid of past posts"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="A Fresh New Website">
					<p>
						Julian designed a unique, modular site with a restrained color palette echoing the brand's vibe. The architecture stayed simple and purposeful, layering pops of personality througout a content-light experience to stay within budget.
					</p>
				</Portfolio.Blurb>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						gridSize={4}
						src={{
							'1x': require('./images/mobile.jpg'),
							'2x': require('./images/mobile@2x.jpg')
						}}
						alt="Mobile screenshot of the website's homepage"
					/>
					<Portfolio.FlushImages.Image
						gridSize={8}
						src={{
							'1x': require('./images/desktop.jpg'),
							'2x': require('./images/desktop@2x.jpg')
						}}
						alt="Desktop screenshot of the website's homepage"
					/>
				</Portfolio.FlushImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/ux.jpg'),
							'2x': require('./images/ux@2x.jpg')
						}}
						alt="UX breakdown of solving the homepage strategy"
					/>
				</Portfolio.StackedImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="875"
						src={{
							'1x': require('./images/mobile-screens.jpg'),
							'2x': require('./images/mobile-screens@2x.jpg')
						}}
						alt="Mobile screenshots of the website"
					/>
				</Portfolio.StackedImages>

				<Portfolio.UpNext to="/work/cline" title="Cline Cellars" />

			</Portfolio>
		)
	}

}
