import React from 'react'
import Animated from '@/components/Animated'
import styles from './styles.module.scss'

export default class AboutRoute extends React.PureComponent {

	render () {
		return (
			<div className={styles.wrapper}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
							<Animated
								type="fadeIn"
								element={(
									<div className={styles.hero}>
										<img src={require('./images/quote.svg')} className={styles.quote} />
										<p>Julian is a product designer living in the Bay Area. With more than 15 years of creative experience, and a few design awards under his belt, he’s had the pleasure of partnering with WD40, Chicken of the Sea, AutoDesk, Level10 Construction and others.</p>
										<p>Julian stays busy as a Product Design Lead at a FinTech company in Sausalito but feel free to <a href="mailto:jcaustrita@gmail.com">contact</a> him about collaborating on a project.</p>
									</div>
								)}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}

}
