import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class Tribe extends React.PureComponent {

	render () {
		return (
			<Portfolio title="Tribe" shareImage={require('./images/og-tribe.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/tribe.jpg'),
						'2x': require('./images/tribe@2x.jpg'),
						'mobile': require('./images/tribe-mobile.jpg')
					}}
					alt="Tribe"
				/>

				<Portfolio.Blurb headline="Tribe" role="UX / Art Direction / Copy Editing / Design">
					<p>Think Linkedin meets motorcycle club meets cannabis lovers. Tribe is a private Marin County Chapter focused on bringing like-minded people together to extend their communities, try new activities, and enjoy a smoke. The Tribe founders needed a strategic plan that outlined how to grow their membership club in stages and build a turn-key solution for future Chapters.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/intro-1.jpg'),
							'2x': require('./images/intro-1@2x.jpg')
						}}
						alt="Tribe's Homepage Login Screen"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/intro-2.jpg'),
							'2x': require('./images/intro-2@2x.jpg')
						}}
						alt="Tribe's Design"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="A Quick 1-2-3 Launch!">
					<p>
						Branding was an immediate need to launch Tribe so that was executed ASAP. Julian then lead and identified the “must haves” for a Phase 1 launch and what could be pushed into a Phase 2 launch plan. This was key to the project’s success. Julian focused on building the marketing website with a member application form first. While the site captured email addresses from interested members, Julian continued the strategy plan for Phase 2, which includes a full members-only dashboard, event calendar, and exclusive product inventory loaded with grower information and super nerdy cannabis stuff.
					</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/01-branding.jpg'),
							'2x': require('./images/01-branding@2x.jpg')
						}}
						alt="Logo"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FlushImages>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/02-marketing.gif'),
							'2x': require('./images/02-marketing@2x.gif')
						}}
						alt="Cool Ass Image of UX Stuff"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/03-turnkey.jpg'),
							'2x': require('./images/03-turnkey@2x.jpg')
						}}
						alt="Cool Ass Image of UX Stuff"
					/>
				</Portfolio.FlushImages>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/fullwidth-story.jpg'),
						'2x': require('./images/fullwidth-story@2x.jpg'),
						'mobile': require('./images/fullwidth-story-mobile@2x.jpg')
					}}
					alt="Cool Ass Image of Tribe"
				/>

				<Portfolio.Blurb headline="Robustly Small">
					<p>
						A light, flexible modular design system was designed to tell the Tribe story digitally. Keeping the visual system simple allowed for a modern vibe Tribe wanted for their brand. The CMS accommodates various spacing controls and color themes that automatically change the modules from a black, gray, or white background. This strategy and functionality allows Tribe to create visually appealing page layouts with only few building blocks. Tribe not only wanted to edit their content on but needed a CMS solution that would scale with their future gated membership platform.
					</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/cms-mobile.gif'),
							'2x': require('./images/cms-mobile.gif')
						}}
						alt="CMS Introduction"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FlushImages>
					<Portfolio.FlushImages.Image
						gridSize={4}
						src={{
							'1x': require('./images/cms-light-mobile.jpg'),
							'2x': require('./images/cms-light-mobile@2x.jpg')
						}}
						alt="Flexible System with Light Background"
					/>
					<Portfolio.FlushImages.Image
						gridSize={8}
						src={{
							'1x': require('./images/cms-light-desktop.jpg'),
							'2x': require('./images/cms-light-desktop@2x.jpg')
						}}
						alt="Flexible System with Light Background"
					/>
				</Portfolio.FlushImages>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						gridSize={8}
						src={{
							'1x': require('./images/cms-dark-desktop.jpg'),
							'2x': require('./images/cms-dark-desktop@2x.jpg')
						}}
						alt="Flexible System with Dark Background"
					/>
					<Portfolio.FlushImages.Image
						gridSize={4}
						src={{
							'1x': require('./images/cms-dark-mobile.jpg'),
							'2x': require('./images/cms-dark-mobile@2x.jpg')
						}}
						alt="Flexible System with Dark Background"
					/>
				</Portfolio.FlushImages>

				<Portfolio.Blurb headline="Rinse and Repeat">
					<p>Tribe needed freebee t-shirts for their new members. The broken “T” from the logo sybolizes a new member joining the Tribe community which was a perfect branded element to leverage. To maximize the budget, a single color screen print was done that continued the black and white brand aesthetic.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/shwag.jpg'),
							'2x': require('./images/shwag@2x.jpg')
						}}
						alt="Makin' Dope Member Swag"
					/>
				</Portfolio.StackedImages>

				<Portfolio.UpNext to="/work/lindsay/" title="Lindsay" />

			</Portfolio>
		)
	}

}
