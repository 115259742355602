import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class Cline extends React.PureComponent {

	render () {
		return (
			<Portfolio title="Cline Cellars" shareImage={require('./images/og-cline.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/cline.jpg'),
						'2x': require('./images/cline@2x.jpg'),
						'mobile': require('./images/cline-mobile.jpg')
					}}
					alt="Cline"
				/>

				<Portfolio.Blurb headline="Cline Cellars" role="UX / Content Strategy / Design">
					<p>Cline is one of the oldest family owned wineries in Sonoma, California. Not only did Cline need an updated site experience but they needed a flexible visual desgn system that could also be used for their sister winery, Jaccuzi Vineyards. Julian’s strategy and design elevated both brand's digital experience to meet the high standards set by their wines.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/homepage.jpg'),
							'2x': require('./images/homepage@2x.jpg')
						}}
						alt="Cline's Homepage"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/homepage-laptop.jpg'),
							'2x': require('./images/homepage-laptop@2x.jpg')
						}}
						alt="Cline's Homepage"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb>
					<Portfolio.Blurb.Headline>Image <span className="strike">Heavy</span> Light</Portfolio.Blurb.Headline>
					<p>Photography was important for Cline. With perfectly planned dimensions, Julian developed a singlular component that used mixed media in multiple layout variations. Page weight became a concern due to the image assets, but he was able to provide hi-res image experiences by finding the perfect compression point that only added 75-125kb of extra weight per component.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="875"
						src={{
							'1x': require('./images/ux.jpg'),
							'2x': require('./images/ux@2x.jpg')
						}}
						alt="UX Process Image"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/images-laptop.jpg'),
							'2x': require('./images/images-laptop@2x.jpg')
						}}
						alt="UX Process Image"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="2 Websites Using 1 Flexible System">
					<p>Julian’s solution was that each brand's site needed to share a single set of light-weight page templates. By defining the global elements like buttons, typography, color and texture, each winery’s module could then represent the brand’s visual style by simply editing the CSS.</p>
				</Portfolio.Blurb>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/fullwidth-cline-story.jpg'),
							'2x': require('./images/fullwidth-cline-story@2x.jpg')
						}}
						alt="Cool Ass Image of Cline Stuff"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/fullwidth-jacuzzi-story.jpg'),
							'2x': require('./images/fullwidth-jacuzzi-story@2x.jpg')
						}}
						alt="Cool Ass Image of Cline Stuff"
					/>
				</Portfolio.FlushImages>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						gridSize={8}
						src={{
							'1x': require('./images/brand-cline-desktop.jpg'),
							'2x': require('./images/brand-cline-desktop@2x.jpg')
						}}
						alt="Cool Ass Image of Cline Stuff"
					/>
					<Portfolio.FlushImages.Image
						gridSize={4}
						src={{
							'1x': require('./images/brand-cline-modules.jpg'),
							'2x': require('./images/brand-cline-modules@2x.jpg')
						}}
						alt="Cool Ass Image of Cline Stuff"
					/>
				</Portfolio.FlushImages>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						gridSize={4}
						src={{
							'1x': require('./images/brand-jacuzzi-modules.jpg'),
							'2x': require('./images/brand-jacuzzi-modules@2x.jpg')
						}}
						alt="Cool Ass Image of Cline Stuff"
					/>
					<Portfolio.FlushImages.Image
						gridSize={8}
						src={{
							'1x': require('./images/brand-jacuzzi-desktop.jpg'),
							'2x': require('./images/brand-jacuzzi-desktop@2x.jpg')
						}}
						alt="Cool Ass Image of Cline Stuff"
					/>
				</Portfolio.FlushImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/mobile.jpg'),
							'2x': require('./images/mobile@2x.jpg')
						}}
						alt="Cline's Drop the Mic Pic"
					/>
				</Portfolio.StackedImages>

				<Portfolio.UpNext to="/work/tribe" title="Tribe" />

			</Portfolio>
		)
	}

}
