import React from 'react'
import PropTypes from 'prop-types'

class Image extends React.PureComponent {

	static propTypes = {
		src: PropTypes.any.isRequired
	}

	buildSrcSet () {
		const srcset = Object.entries(this.props.src).reduce((srcset, [ size, src ]) => {
			srcset.push(`${src} ${size}`)
			return srcset
		}, [])
		return srcset.join(', ')
	}

	render () {
		const { src, ...props } = this.props
		if(typeof src === 'string') {
			return <img src={src} {...props} />
		} else {
			return <img srcSet={this.buildSrcSet()} {...props} />
		}
	}

}

export default Image
