import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import flatten from 'lodash/flatten'
import { Link } from '@reach/router'
import Animated from '@/components/Animated'
import AppFooter from '@/components/AppFooter'
import Image from '@/components/Image'
import OGTags from '@/components/OGTags'
import './styles.module.scss'

export default class Portfolio extends React.PureComponent {

	static propTypes = {
		title: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired,
		shareImage: PropTypes.string
	}

	static FullWidthImage = class PortfolioFullWidthImage extends React.PureComponent {

		static propTypes = {
			src: PropTypes.shape({
				'1x': PropTypes.string.isRequired,
				'2x': PropTypes.string.isRequired,
				'mobile': PropTypes.string.isRequired
			}).isRequired,
			alt: PropTypes.string.isRequired
		}

		render () {
			const { src, alt } = this.props
			const props = {
				className: 'full-width vr',
				alt
			}
			return (
				<picture>
					<source srcSet={`${src['1x']} 1x, ${src['2x']} 2x`} media="(min-width: 48em)" {...props} />
					<source srcSet={src['mobile']} media="(max-width: 48em)" {...props} />
					<img src={src['1x']} {...props} />
				</picture>
			)
		}

	}

	static Image = class PortfolioImage extends React.PureComponent {

		static propTypes = {
			size: PropTypes.string.isRequired,
			vr: PropTypes.string
		}

		render () {
			const { size, vr, ...props } = this.props
			const vrClass = (vr) ? `vr-${vr}` : 'vr'
			return (
				<Image
					className={`img-${size} img-portfolio ${vrClass}`}
					{...props}
				/>
			)
		}

	}

	static StackedImages = class PortfolioStackedImages extends React.PureComponent {

		static propTypes = {
			children: PropTypes.node.isRequired
		}

		render () {
			const children = flatten([this.props.children])
			const gridMap = {
				'1010': 'col-md-8',
				'875': 'col-md-6',
				'560': 'col-md-5'
			}
			return (
				<div className="container-fluid">
					<div className="row center-xs">
						{children.map((child, idx) => (
							<div className={`col-xs-12 ${gridMap[child.props.size]}`} key={idx}>
								{child}
							</div>
						))}
					</div>
				</div>
			)
		}

	}

	static Blurb = class PortfolioBlurb extends React.PureComponent {

		static propTypes = {
			headline: PropTypes.string,
			children: PropTypes.node.isRequired,
			role: PropTypes.string
		}

		static Headline = class PortfolioBlurbHeadline extends React.PureComponent {

			static propTypes = {
				children: PropTypes.node
			}

			render () {
				return <h3><em>{this.props.children}</em></h3>
			}

		}

		static Role = class PortfolioBlurbRole extends React.PureComponent {

			static propTypes = {
				children: PropTypes.node
			}

			render () {
				return <p>Role: <span className="gray">{this.props.children}</span></p>
			}

		}

		render () {
			const { Headline, Role } = this.constructor
			return (
				<div className="container-fluid">
					<div className="row">
						<div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
							<div className="vr">
								{this.props.headline && <Headline>{this.props.headline}</Headline>}
								{this.props.children}
								{this.props.role && <Role>{this.props.role}</Role>}
							</div>
						</div>
					</div>
				</div>
			)
		}

	}

	static FlushImages = class PortfolioFlushImages extends React.PureComponent {

		static defaultProps = {
			vr: false
		}

		static propTypes = {
			vr: PropTypes.bool,
			children: PropTypes.node.isRequired
		}

		static Image = class PortfolioFlushImagesImage extends React.PureComponent {

			static defaultProps = {
				gridSize: 6
			}

			static propTypes = {
				gridSize: PropTypes.number
			}

			render () {
				const { gridSize, ...props } = this.props
				const colSm = `col-sm-${gridSize}`
				return (
					<div className={`col-xs-12 ${colSm} flush-padding`}>
						<Image
							className="full-width"
							style={{ display: 'block' }}
							{...props}
						/>
					</div>
				)
			}

		}

		render () {
			const childProps = (this.props.vr) ? (
				{}
			) : (
				{ style: { marginBottom: 0 } }
			)
			const wrapperClassName = classnames(
				'container-fluid flush-padding',
				{ vr: this.props.vr }
			)
			return (
				<div className={wrapperClassName} {...childProps}>
					<div className="row top-xs flush-margin">
						{this.props.children}
					</div>
				</div>
			)
		}

	}

	static UpNext = class PortfolioUpNext extends React.PureComponent {

		static propTypes = {
			title: PropTypes.string.isRequired,
			to: PropTypes.string.isRequired
		}

		render () {
			return (
				<div className="container-fluid">
					<div className="row">
						<div className="col-xs-12 center-xs">
							<div className="vr">
								<p>
									<span className="gray">Up Next</span><br />
									<Link to={this.props.to}>{this.props.title}</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}

	render () {
		return (
			<>
				<Helmet>
					<title>{this.props.title}</title>
				</Helmet>
				{this.props.shareImage && (
					<OGTags
						image={this.props.shareImage}
					/>
				)}
				<Animated
					type="fadeIn"
					element={(
						<div>
							{this.props.children}
							<AppFooter />
						</div>
					)}
				/>
			</>
		)
	}

}
