import React from 'react'
import { Router } from '@reach/router'

import Homepage from '@/routes/Homepage'
import About from '@/routes/About'
import Work from '@/routes/Work'

class AppRouter extends React.PureComponent {

	render () {
		return (
			<Router>
				<Homepage path="/" />
				<About path="/about" />
				<Work path="/work/*" />
			</Router>
		)
	}

}

export default AppRouter
