import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class Lindsay extends React.PureComponent {

	render () {
		return (
			<Portfolio title="Lindsay" shareImage={require('./images/og-lindsay.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/lindsay.jpg'),
						'2x': require('./images/lindsay@2x.jpg'),
						'mobile': require('./images/lindsay-mobile.jpg')
					}}
					alt="Lindsay"
				/>

				<Portfolio.Blurb headline="Lindsay" role="UX / Art Direction / Design">
					<p>Lindsay is an iconic olive company whose products can be found in the pantries of thousands. Julian has been a creative strategist and the lead designer for two of their site redesigns, newsletters, contests and social campaigns.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/homepage.jpg'),
							'2x': require('./images/homepage@2x.jpg')
						}}
						alt="Lindsay's Homepage"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/homepage-laptop.jpg'),
							'2x': require('./images/homepage-laptop@2x.jpg')
						}}
						alt="Lindsay's Recipes"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Discovering Tasty Recipes">
					<p>Finding recipes quickly and easily is HUGE for Lindsay's site visitors. Julian’s research pushed him to ditch standard filtering components because they were too utilitarian and didn’t promote enough discovery. Instead, he fused a dropdown menu into the messaging.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="875"
						src={{
							'1x': require('./images/ux.jpg'),
							'2x': require('./images/ux@2x.png')
						}}
						alt="Discovering Tasty Recipes :: UX"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/recipes-desktop.jpg'),
							'2x': require('./images/recipes-desktop@2x.jpg')
						}}
						alt="Discovering Tasty Recipes :: Recipes"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/fullwidth-story.jpg'),
						'2x': require('./images/fullwidth-story@2x.jpg'),
						'mobile': require('./images/fullwidth-story-mobile@2x.jpg')
					}}
					alt="Cool Ass Image of Lindsay Stuff"
				/>

				<Portfolio.Blurb headline="Fun Brand. Fun Design!">
					<p>“Fun” was the key expression to sprinkle throughout all digital avenues. This was achieved by introducing new typography treatments, creating engaging content, lightening the brand voice and defining small interaction animations at key touch-points.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/fun-laptop.jpg'),
							'2x': require('./images/fun-laptop@2x.jpg')
						}}
						alt="Fun Brand. Fun Design! :: Products"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/fun-system.jpg'),
							'2x': require('./images/fun-system@2x.jpg')
						}}
						alt="Fun Brand. Fun Design! :: System"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="A Mobile First Design">
					<p>Several iterations of the product section were explored to find the best way to explore Lindsay’s product lines. Julian wanted the user to seamlessly learn about Lindsay's product offerings and to easily jump back and forth for greater discovery. Overlays were used to maximize page speed and ease of use.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/mobile.jpg'),
							'2x': require('./images/mobile@2x.jpg')
						}}
						alt="Lindsay's Drop the Mic Pic"
					/>
				</Portfolio.StackedImages>

				<Portfolio.UpNext to="/work/comfort-zone" title="Comfort Zone" />

			</Portfolio>
		)
	}

}
