import React from 'react'
import './grained'
import './grained.module.scss'

export default class Grained extends React.PureComponent {

	componentDidMount () {
		const { document, grained } = window
		document.body.setAttribute('id', 'grain')
		grained('#grain', {})
	}

	render () {
		return null
	}

}
