import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class SSI extends React.PureComponent {

	render () {
		return (
			<Portfolio title="SSI" shareImage={require('./images/og-ssi.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/ssi.jpg'),
						'2x': require('./images/ssi@2x.jpg'),
						'mobile': require('./images/ssi-mobile.jpg')
					}}
					alt="SSI"
				/>

				<Portfolio.Blurb headline="SSI" role="UX / Art Direction / Design">
					<p>Surface System &amp; Instruments (SSI) specializes in software and equipment for testing surface smoothness. SSI looked to Julian to modernize their 20 year old brand. Julian focused on refining the brand’s visual identity and simplifying the site experience (it was a hot mess).</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/logo.jpg'),
							'2x': require('./images/logo@2x.jpg')
						}}
						alt="SSI's Rad Logo"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Modernize the Branding">
					<p>The brand uses 3 different ways it refers to it’s self: Surface Systems &amp; Instruments, Smooth Road, and SSI. The new identity needed to expand on the existing logo, express precision and to give hierarchy to all 3 names. The final logo embodied all.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/branding.jpg'),
							'2x': require('./images/branding@2x.jpg')
						}}
						alt="Brand Refinement"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Smoothing Out Complexity">
					<p>Julian spent the majority of the budget creating strategy documents that outlined page requirements, component functionality and new site map concepts. Rapid prototyping resulted to the huge success of the website refresh.</p>
					<p>The use of a subtle site texture, typography, limited color palette and an absence of ostentatious elements served to be helpful in supporting Julian’s vision of the new brand.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/ux.jpg'),
							'2x': require('./images/ux@2x.jpg')
						}}
						alt="Smoothing Out Complexity :: UX"
					/>
				</Portfolio.StackedImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/homepage.jpg'),
							'2x': require('./images/homepage@2x.jpg')
						}}
						alt="Smoothing Out Complexity :: Homepage"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/products.jpg'),
							'2x': require('./images/products@2x.jpg')
						}}
						alt="Smoothing Out Complexity :: Products"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Designing for SSI's Future">
					<p>Julian aimed to create an intuitive navigation experience and a strong architectual foundation for future page template improvements. Phase 1 was all about identifying and restructuring content along with creating greater touch-points for capturing lead gens.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/future-mobile.jpg'),
							'2x': require('./images/future-mobile@2x.jpg')
						}}
						alt="Designing for SSI's Future"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/fullwidth-story.jpg'),
						'2x': require('./images/fullwidth-story@2x.jpg'),
						'mobile': require('./images/fullwidth-story-mobile@2x.jpg')
					}}
					alt="Cool Ass Image of SSI Stuff"
				/>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/laptop.jpg'),
							'2x': require('./images/laptop@2x.jpg')
						}}
						alt="SSI's Drop the Mic Pic"
					/>
				</Portfolio.StackedImages>

				<Portfolio.UpNext to="/work/detente" title="Détente" />

			</Portfolio>
		)
	}

}
