import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class BeerCraft extends React.PureComponent {

	render () {
		return (
			<Portfolio title="BeerCraft" shareImage={require('./images/og-beercraft.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/beercraft.jpg'),
						'2x': require('./images/beercraft@2x.jpg'),
						'mobile': require('./images/beercraft-mobile.jpg')
					}}
					alt="BeerCraft"
				/>

				<Portfolio.Blurb headline="BeerCraft" role="UX / Art Direction / Copy Writing / Design">
					<p>A beer geek’s dream. Part beer club, part bottle shop and part taproom. Julian was asked to lead BeerCraft's digital and creative strategy to create an online beer club membership platform and taproom experience.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/homepage-lead.jpg'),
							'2x': require('./images/homepage-lead@2x.jpg')
						}}
						alt="BeerCraft's Homepage"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="BeerCraft’s Beer Club">
					<p>BeerCraft has found huge success in their in-store beer club and decided to launch a new company leveraging an existing url the owners had purchased years ago, www.californiabeerclub. Julian maximized the budget by strategically leveraging existing styles and modules from the parent site. Julian dedicated the remainder of his time creating new pricing, cart, and membership templates for a refreshingly simple beer club experience.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/california-club-1.jpg'),
							'2x': require('./images/california-club-1@2x.jpg')
						}}
						alt="BeerCraft's California Beer Club"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/california-club-2.jpg'),
							'2x': require('./images/california-club-2@2x.jpg')
						}}
						alt="BeerCraft's California Beer Club"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Designing the Perfect Promo">
					<p>Marketing during the holidays is a key objective for BeerCraft, so a flexible promo strategy was needed. Julian knew that rich imagery and compact messaging could be blended in a creative way to maximize space while adding great visual appeal.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="875"
						src={{
							'1x': require('./images/ux.jpg'),
							'2x': require('./images/ux@2x.jpg')
						}}
						alt="Designing the Perfect Promo"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/promos.jpg'),
							'2x': require('./images/promos@2x.jpg')
						}}
						alt="BeerCraft's Promos"
						vr="light"
					/>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/promos-animated.gif'),
							'2x': require('./images/promos-animated@2x.gif')
						}}
						alt="BeerCraft's Promos"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/fullwidth-story.jpg'),
						'2x': require('./images/fullwidth-story@2x.jpg'),
						'mobile': require('./images/fullwidth-story-mobile@2x.jpg')
					}}
					alt="BeerCraft"
				/>

				<Portfolio.Blurb headline="Beer Copywriting">
					<p>Although he’s not a ‘copy writer’, Julian can string together a few strong headlines now and again, especially if it’s about beer. Julian increased visitor’s time-on-page and site engagement through memorable storytelling using humor and enthusiasm.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/copy-desktop.jpg'),
							'2x': require('./images/copy-desktop@2x.jpg')
						}}
						alt="Writing for Beer Folk"
					/>
					<Portfolio.Image
						size="875"
						src={{
							'1x': require('./images/copy-mobile.jpg'),
							'2x': require('./images/copy-mobile@2x.jpg')
						}}
						alt="Writing for Beer Folk"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Creating Event Swag">
					<p>Being that BeerCraft is a taproom, they tend to have a few parties. This t-shirt concept was for an event called, “The Knights of the Dank Table, Quest for the Holy Whale”. The design blends the event's 4 guest breweries into to the holy <span className="strike">grail</span> stein.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/shwag.jpg'),
							'2x': require('./images/shwag@2x.jpg')
						}}
						alt="Makin' Rad Event Schwag"
					/>
				</Portfolio.StackedImages>

				<Portfolio.UpNext to="/work/high-road-edibles" title="High Road Edibles" />

			</Portfolio>
		)
	}

}
