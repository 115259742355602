import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from '@reach/router'
import './styles.module.scss'
import Animated from '@/components/Animated'
import Work from '@/routes/Work'

export default class HomepageRoute extends React.PureComponent {

	render () {
		return (
			<>
				<Helmet>
					<title>Work</title>
				</Helmet>
				<Animated
					type="fadeIn"
					element={(
						<div className="col-xs block-lead">
							<h1>Product Designer and UX Nerd</h1>
						</div>
					)}
				/>
				{Work.nav.map(({ title, slug }) => (
					<Link to={`/work/${slug}`} className={`block block-dark ${slug}`} key={slug}>
						<span className="work-title">{title}</span>
					</Link>
				))}
			</>
		)
	}

}
