import React from 'react'
import Portfolio from '@/components/Portfolio'

export default class Detente extends React.PureComponent {

	render () {
		return (
			<Portfolio title="Détente Wines" shareImage={require('./images/og-detente.jpg')}>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/detente.jpg'),
						'2x': require('./images/detente@2x.jpg'),
						'mobile': require('./images/detente-mobile.jpg')
					}}
					alt="Détente"
				/>

				<Portfolio.Blurb headline="Détente" role="Partner / Art Direction / Branding / UX / Design">
					<p>Détente (dey-tahnt) - an easing of tension. This young winery produces small lot wines from Paso Robles California. A town divided by Highway 101, some say that the East does it better while others say the West is best. Détente on the other hand sources grapes from both sides so intentionally, embraces the commentary on the East-West ‘tension’. The word Détente was coined during the Cold War inspiring the Partners and Julian to brand the products in a unique way. <a href="https://www.detentewines.com">View Website</a></p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/bottles.jpg'),
							'2x': require('./images/bottles@2x.jpg')
						}}
						alt="3 wine bottles"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Branded Tension">
					<p>
						The goal was to build a brand that solved challenging constraints: 2 of the 3 partners didn’t want their identities revealed (initially), no tasting room, wines were young, and no marketing strategy for an overly saturated wine market in the Central Coast. Julian created a logo tightly encased while reducing tension with the mark’s usage. Julian used mystery, propaganda, and a limited palette to inspire a gorilla marketing strategy to create a fresh experience on a rising brand.
					</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/branding.jpg'),
							'2x': require('./images/branding@2x.jpg')
						}}
						alt="Brand typography and color palette"
					/>
				</Portfolio.StackedImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/businesscard.jpg'),
							'2x': require('./images/businesscard@2x.jpg')
						}}
						alt="Business card design"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Wine Labels with Stories">
					<p>
						Wine tasting is a sensory experience and Détente’s labels are an intentional extention. The labels are designed with hidden meanings that are meant to be held, examined, and discussed. Julian works closely with the other Partners to match each wine’s personality with different creative ideas exploring espionage, redacted documents, codes, and reconnaissance.
					</p>
				</Portfolio.Blurb>

				<Portfolio.FlushImages>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/label1.jpg'),
							'2x': require('./images/label1@2x.jpg')
						}}
						alt="Margot pinot wine label: Enigma code design"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/label2.jpg'),
							'2x': require('./images/label2@2x.jpg')
						}}
						alt="Eastern Bloc wine label: Map of Paso Robles with AVAs highlighted on the East"
					/>
				</Portfolio.FlushImages>

				<Portfolio.FlushImages>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/label3.jpg'),
							'2x': require('./images/label3@2x.jpg')
						}}
						alt="Classified wine label: Redacted document"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/label4.jpg'),
							'2x': require('./images/label4@2x.jpg')
						}}
						alt="Rosé wine label: 'Topography' of Paso Robles"
					/>
				</Portfolio.FlushImages>

				<Portfolio.FullWidthImage
					src={{
						'1x': require('./images/fullwidth-story.jpg'),
						'2x': require('./images/fullwidth-story@2x.jpg'),
						'mobile': require('./images/fullwidth-story-mobile.jpg')
					}}
					alt="Partner Trevor drinking Classified"
				/>

				<Portfolio.Blurb>
					<Portfolio.Blurb.Headline>The <span className="strike">Un</span>familiar Instagram Strategy</Portfolio.Blurb.Headline>
					<p>With the help of Détente’s Marketing Director, Julian co-developed a strategy for Instagram content to be monochromatic, with occasional pops of red, weave in rich graphical triptych panels, and leverage vintage advertising creative for news related messaging. Due to limited resources, and the Partner's photography abilities, these photographic styles would create a semi-consistent filter and a visually different experience for the followers' oversaturated feeds.</p>
				</Portfolio.Blurb>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="1010"
						src={{
							'1x': require('./images/insta.jpg'),
							'2x': require('./images/insta@2x.jpg')
						}}
						alt="Instagram grid of past posts"
					/>
				</Portfolio.StackedImages>

				<Portfolio.Blurb headline="Website Propaganda!">
					<p>Julian included digital signal interference, text scrambling, and scalable typography in a minimal design system to extend the brand's theme. The website was solved within a limited budget for maximum visual impact and usability. Détente is a true example of what Julian sees 'Product Design' as – imagining and creating a design system that solves a problem for any given market (not limited to web apps). Détente shows Julian’s ability to see both the big and small picture of a growing brand and 'effortlessly' connecting all touchpoints for the best experience.</p>
				</Portfolio.Blurb>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						gridSize={8}
						src={{
							'1x': require('./images/desktop.jpg'),
							'2x': require('./images/desktop@2x.jpg')
						}}
						alt="Desktop screenshot of the website's homepage"
					/>
					<Portfolio.FlushImages.Image
						gridSize={4}
						src={{
							'1x': require('./images/mobile.jpg'),
							'2x': require('./images/mobile@2x.jpg')
						}}
						alt="Mobile screenshot of the website's homepage"
					/>
				</Portfolio.FlushImages>

				<Portfolio.StackedImages>
					<Portfolio.Image
						size="560"
						src={{
							'1x': require('./images/mobile2.jpg'),
							'2x': require('./images/mobile2@2x.jpg')
						}}
						alt="Mobile screenshots of the website"
					/>
				</Portfolio.StackedImages>

				<Portfolio.FlushImages vr={true}>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/lifestyle1.jpg'),
							'2x': require('./images/lifestyle1@2x.jpg')
						}}
						alt="Aerial shot of the Vermouth"
					/>
					<Portfolio.FlushImages.Image
						src={{
							'1x': require('./images/lifestyle2.jpg'),
							'2x': require('./images/lifestyle2@2x.jpg')
						}}
						alt="Vineyard shot of grapes"
					/>
				</Portfolio.FlushImages>

				<Portfolio.UpNext to="/work/lightwell-survey" title="Lightwell Survey" />

			</Portfolio>
		)
	}

}
