import React from 'react'
import { Helmet } from 'react-helmet'
import Grained from '@/components/Grained'
import ScrollToTop from '@/components/ScrollToTop'
import AppLayout from '@/components/AppLayout'
import AppRouter from '@/components/AppRouter'
import OGTags from '@/components/OGTags'
import './styles'
import './index.css'


class App extends React.PureComponent {

	render () {

		return (
			<>
				<Helmet
					titleTemplate="Julian Caustrita :: %s"
				/>
				<OGTags
					image={require('@/static/og-me.jpg')}
					site_name="Julian Caustrita"
					description="Julian is a product designer in the Bay Area."
				/>
				<Grained />
				<ScrollToTop />
				<AppLayout>
					<AppRouter />
				</AppLayout>
			</>
		)
	}

}

export default App
