import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './styles.module.scss'

class Animated extends React.PureComponent {

	static propTypes = {
		element: PropTypes.node.isRequired,
		type: PropTypes.string.isRequired
	}

	render () {
		const { element, type } = this.props
		const className = classnames(
			styles.animated,
			styles[type],
			element.props.className
		)
		return React.cloneElement(this.props.element, {
			className
		})
	}

}

export default Animated
