import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link, globalHistory } from '@reach/router'
import Animated from '@/components/Animated'
import Work from '@/routes/Work'
import './styles.module.scss'

class AppLayout extends React.PureComponent {

	static propTypes = {
		children: PropTypes.node
	}

	state = {
		overlayActive: false
	}

	componentDidMount() {
		this.historyUnsubscribe = globalHistory.listen(() => {
			this.setState({
				overlayActive: false
			})
		})
	}

	componentWillUnmount() {
		this.historyUnsubscribe()
	}

	handleMenuIcon = () => {
		this.setState({
			overlayActive: !this.state.overlayActive
		})
	}

	render () {

		const appClasses = [
			'app',
			{
				's-overlay': this.state.overlayActive
			}
		]

		return (
			<div className={classnames(appClasses)}>

				<div className="overlay">
					<ul className="menu-links">
						<li><Link to="/"><em>Work</em></Link></li>
						{Work.nav.map(({ slug, title }) => (
							<li key={slug}><Link to={`/work/${slug}`} className="nav-child">{title}</Link></li>
						))}
						<li><Link to="/about"><em>About</em></Link></li>
						<li><a href="mailto:jcaustrita@gmail.com"><em>Contact</em></a></li>
					</ul>
				</div>

				<Animated
					type="fadeInDown"
					element={(
						<div className="header">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xs-12">
										<div className="menu-icon" onClick={this.handleMenuIcon}>
											<div className="bar1"></div>
											<div className="bar2"></div>
											<div className="bar3"></div>
										</div>
										<h1>
											<Link to="/">
												<img src={require('./julian.svg')} className="julian" />
											</Link>
										</h1>
									</div>
								</div>
							</div>
						</div>
					)}
				/>

				{this.props.children}

			</div>
		)
	}

}

export default AppLayout
